$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #c5cae9;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #1976d2;
$indigo: #303f9f;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #ff9800;
$yellow: #ffc107;
$green: #388e3c;
$teal: #20c997;
$cyan: #17a2b8;

$primary: $green;
$secondary: #689f38;
$success: $blue;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-200;
$dark: $gray-800;

$enable-rounded: false;
$enable-shadows: true;

$body-color: $gray-700;
