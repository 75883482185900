.header {
}

@media (max-width: 992px) {
  .header {
  }
  .contact-info {
    text-align: center;
  }
  section h1 {
    font-size: 2rem;
  }
  section h2,
  section h3 {
    font-size: 1.5rem;
  }
}
