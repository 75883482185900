.main-nav {
  background-color: $white;
  border-top: 6px solid $primary;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.logo {
  strong {
    color: #225725;
    font-size: 1.5rem;
    display: block;

    border-bottom: 2px solid $primary;
    padding-bottom: 5px;
  }
  span {
    color: $primary;
    display: block;
  }
}
